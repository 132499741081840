import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Chip,
  Divider,
  Link,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/material/styles";

import VirusTotalIcon from "../../../assets/virustotal-logo.svg";
import EmailRepIcon from "../../../assets/email-rep-logo.png";
import IPInfoIcon from "../../../assets/ipinfo-logo.svg";
import URLScanIOIcon from "../../../assets/urlscan-logo.svg";

/* ----------------- CONFIG ------------------ */
const VALUE_TRUNCATE_LENGTH = 20;

function prettifyKey(key: string): string {
  return key
    .split("_")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
}

function formatValue(value: any): string {
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  return String(value ?? "");
}

/** Enricher name -> Icon mapping */
const ENRICHER_ICON_MAP: Record<string, string> = {
  VirusTotal: VirusTotalIcon,
  EmailRep: EmailRepIcon,
  IPInfo: IPInfoIcon,
  URLScanIO: URLScanIOIcon,
};

export interface Artifact {
  enrichments?: {
    [enricherName: string]: {
      data?: Record<string, any>;
      enricher?: string; // e.g. "VirusTotal"
    };
  };
}

interface ArtifactDetailsProps {
  artifactKey: string;
  artifact: Artifact;
}

/**
 * Displays:
 * - `artifactKey` at the top
 * - A row of enricher chips (with icons)
 * - Collapsible sections for each enricher's data
 */
const ArtifactDetails: React.FC<ArtifactDetailsProps> = ({
  artifactKey,
  artifact,
}) => {
  const enrichments = artifact.enrichments ?? {};
  const enricherEntries = Object.entries(enrichments);

  if (enricherEntries.length === 0) {
    return (
      <Box p={2}>
        <Typography variant="body2" fontWeight="bold">
          {artifactKey}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          No enrichments found.
        </Typography>
      </Box>
    );
  }

  const enricherNames = enricherEntries.map(([_, obj]) => obj.enricher || _);

  return (
    <Box sx={{ p: 2, minWidth: 300 }}>
      <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
        {artifactKey}
      </Typography>

      {/* Row of enricher chips */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
        {enricherNames.map((name, idx) => {
          const iconSrc = ENRICHER_ICON_MAP[name] || null;
          return (
            <Chip
              key={`${name}-${idx}`}
              variant="outlined"
              size="small"
              label={name}
              icon={
                iconSrc ? (
                  <Box
                    component="img"
                    sx={{ width: 16, height: 16 }}
                    src={iconSrc}
                    alt={name}
                  />
                ) : undefined
              }
              sx={{
                fontWeight: "bold",
                borderRadius: "16px",
                fontSize: "0.75rem",
                height: "2.0rem",
                padding: "2px",
              }}
            />
          );
        })}
      </Box>

      {/* One collapsible section per enricher */}
      {enricherEntries.map(([key, enricherObj]) => (
        <EnricherSection
          key={key}
          enricherName={enricherObj.enricher || key}
          data={enricherObj.data ?? {}}
          iconSrc={ENRICHER_ICON_MAP[enricherObj.enricher || key]}
        />
      ))}
    </Box>
  );
};

export default ArtifactDetails;

/* -------------------------------------------
   EnricherSection: a collapsible block
------------------------------------------- */
interface EnricherSectionProps {
  enricherName: string;
  data: Record<string, any>;
  iconSrc?: string;
}

const EnricherSection: React.FC<EnricherSectionProps> = ({
  enricherName,
  data,
  iconSrc,
}) => {
  const [open, setOpen] = useState(true);
  const sortedKeys = Object.keys(data).sort();

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* Header row: Chip, Copy icon, Expand arrow */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          py: 1,
        }}
      >
        <Chip
          label={enricherName}
          size="small"
          variant="outlined"
          icon={
            iconSrc ? (
              <Box
                component="img"
                src={iconSrc}
                alt={enricherName}
                sx={{ width: 16, height: 16 }}
              />
            ) : undefined
          }
          sx={{
            fontWeight: "bold",
            borderRadius: "16px",
            fontSize: "0.75rem",
            height: "2rem",
            padding: "2px",
            mr: 1,
          }}
        />

        <IconButton
          size="small"
          onClick={handleCopy}
          sx={{ mr: 1 }}
          title="Copy enricher data (JSON)"
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>

        <Box flexGrow={1} />

        {/* Expand/Collapse icon */}
        {open ? (
          <ExpandLessIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => setOpen(!open)}
          />
        ) : (
          <ExpandMoreIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => setOpen(!open)}
          />
        )}
      </Box>

      {/* If open, show data rows */}
      {open && (
        <Box sx={{ ml: 2 }}>
          {sortedKeys.length === 0 ? (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              No data.
            </Typography>
          ) : (
            sortedKeys.map((k, idx) => (
              <React.Fragment key={k}>
                <DataRow rowKey={k} value={data[k]} />
                {idx < sortedKeys.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

/* -------------------------------------------
   DataRow: either:
   - A primitive row (key on left, truncated or expanded value on right)
   - A nested row (object/array) that toggles to reveal NestedData
------------------------------------------- */
interface DataRowProps {
  rowKey: string;
  value: any;
}

const DataRow: React.FC<DataRowProps> = ({ rowKey, value }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const isObject =
    value !== null && typeof value === "object" && !Array.isArray(value);
  const isArray = Array.isArray(value);

  const displayValue = formatValue(value);

  if (isObject || isArray) {
    // Show "Object" or "Array(n)" plus a chevron
    return (
      <Box
        sx={{
          py: 1,
          display: "grid",
          gridTemplateColumns: "60% 40%",
          alignItems: "center",
        }}
      >
        {/* KEY (60%) */}
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            pr: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}
            noWrap
          >
            {prettifyKey(rowKey)}
          </Typography>
        </Box>

        {/* VALUE (40%) + expand icon */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
            overflow: "hidden",
          }}
        >
          <Typography variant="body2">
            {isArray ? `Array(${value.length})` : "Object"}
          </Typography>

          {expanded ? (
            <ExpandLessIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          )}
        </Box>

        {/* If expanded, show nested data */}
        {expanded && (
          <Box sx={{ gridColumn: "1 / span 2", mt: 1, ml: 2 }}>
            <NestedData data={value} />
          </Box>
        )}
      </Box>
    );
  }

  // Otherwise, a primitive => key on left, truncated value on right
  return (
    <Box
      sx={{
        py: 1,
        display: "grid",
        gridTemplateColumns: "60% 40%",
        alignItems: "flex-start",
      }}
    >
      {/* Key */}
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          pr: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontWeight: 500 }}
          noWrap
        >
          {prettifyKey(rowKey)}
        </Typography>
      </Box>

      {/* Value */}
      <Box sx={{ textAlign: "right", pr: 1 }}>
        <InlineExpandText
          text={displayValue}
          maxChars={VALUE_TRUNCATE_LENGTH}
        />
      </Box>
    </Box>
  );
};

/**
 * InlineExpandText: if `text` is longer than `maxChars`,
 * we show a truncated version plus a [More] link that toggles the full text.
 */
interface InlineExpandTextProps {
  text: string;
  maxChars: number;
}

const InlineExpandText: React.FC<InlineExpandTextProps> = ({
  text,
  maxChars,
}) => {
  const [expanded, setExpanded] = useState(false);

  console.log("Text:", text, "Length:", text.length, "MaxChars:", maxChars); // Debugging

  if (text.length <= maxChars) {
    return (
      <Typography variant="body2" noWrap>
        {text}
      </Typography>
    );
  }

  if (text.length > maxChars) {
    return (
      <Typography
        variant="body2"
        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
      >
        {expanded ? text : text.slice(0, maxChars) + "…"}{" "}
        <Link
          component="button"
          variant="body2"
          onClick={() => setExpanded(!expanded)}
          sx={{ ml: 0.5 }}
        >
          [{expanded ? "Less" : "More"}]
        </Link>
      </Typography>
    );
  }

  // if (expanded) {
  //   return (
  //     <Typography
  //       variant="body2"
  //       sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
  //     >
  //       {text}{" "}
  //       <Link
  //         component="button"
  //         variant="body2"
  //         onClick={() => setExpanded(false)}
  //         sx={{ ml: 0.5 }}
  //       >
  //         [Less]
  //       </Link>
  //     </Typography>
  //   );
  // }

  return (
    <Typography variant="body2" noWrap>
      {text.slice(0, maxChars)}…{" "}
      <Link
        component="button"
        variant="body2"
        onClick={() => setExpanded(true)}
        sx={{ ml: 0.5 }}
      >
        [More]
      </Link>
    </Typography>
  );
};

/* -------------------------------------------
   NestedData: recursively show arrays/objects
------------------------------------------- */
interface NestedDataProps {
  data: any;
}

const NestedData: React.FC<NestedDataProps> = ({ data }) => {
  if (Array.isArray(data)) {
    return (
      <Box>
        {data.map((item, idx) => (
          <React.Fragment key={idx}>
            <DataRow rowKey={`[${idx}]`} value={item} />
            {idx < data.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    );
  }

  if (data && typeof data === "object") {
    const keys = Object.keys(data).sort();
    return (
      <Box>
        {keys.map((k, i) => (
          <React.Fragment key={k}>
            <DataRow rowKey={k} value={data[k]} />
            {i < keys.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    );
  }

  // Primitive fallback
  const text = formatValue(data);
  return <InlineExpandText text={text} maxChars={VALUE_TRUNCATE_LENGTH} />;
};

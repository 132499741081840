import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Chip,
  Badge,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import { getHeaderRowStyles } from "../../../utils/themeStyles";

interface FilterPopupProps {
  conclusions: string[];
  statuses: string[];
  severities: string[];
  attackSurfaces: string[];
  mitreStages: string[];

  applyFilters: (filters: any) => void; // For normal usage
  currentFilters: any; // Prepopulation
  onSaveCustomFilter: (builtFilters: any) => void;
  onUpdateCustomFilter?: (
    index: number,
    newName: string,
    newFilters: any
  ) => void;

  editingIndex?: number | null;
  defaultName?: string; // The typed name from parent if editing

  asDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export interface FilterPopupRef {
  getFinalFilters: () => any; // <-- Let the parent call this to read updated advanced-filter data.
}

const FilterPopupRender: ForwardRefRenderFunction<
  FilterPopupRef,
  FilterPopupProps
> = (
  {
    conclusions,
    statuses,
    severities,
    attackSurfaces,
    mitreStages,
    applyFilters,
    currentFilters,
    onSaveCustomFilter,
    onUpdateCustomFilter,
    editingIndex = null,
    defaultName = "",
    asDialog = false,
    open = true,
    onClose = () => {},
  },
  ref
) => {
  const theme = useTheme();
  const headerRowStyles = getHeaderRowStyles(theme);

  const [selectedConclusions, setSelectedConclusions] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [alertStartDate, setAlertStartDate] = useState<Date | null>(null);
  const [alertEndDate, setAlertEndDate] = useState<Date | null>(null);
  const [selectedAttackers, setSelectedAttackers] = useState<string[]>([]);
  const [attackerInput, setAttackerInput] = useState("");
  const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
  const [targetInput, setTargetInput] = useState("");
  const [selectedAttackSurfaces, setSelectedAttackSurfaces] = useState<
    string[]
  >([]);
  const [selectedMitreStages, setSelectedMitreStages] = useState<string[]>([]);
  const [feedbackFilters, setFeedbackFilters] = useState<string[]>([]);

  // Are we in "edit" mode or normal mode?
  const isEditing = editingIndex !== null;

  useEffect(() => {
    if (currentFilters) {
      setSelectedConclusions(currentFilters.conclusions || []);
      setSelectedStatuses(currentFilters.statuses || []);
      setSelectedSeverities(currentFilters.severities || []);
      setSelectedAttackSurfaces(currentFilters.attackSurfaces || []);
      setSelectedMitreStages(currentFilters.mitreStages || []);
      setFeedbackFilters(currentFilters.feedback || []);

      setStartDate(
        currentFilters.createdAt ? currentFilters.createdAt[0] : null
      );
      setEndDate(currentFilters.createdAt ? currentFilters.createdAt[1] : null);
      setAlertStartDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[0] : null
      );
      setAlertEndDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[1] : null
      );
      setSelectedAttackers(currentFilters.attackers || []);
      setSelectedTargets(currentFilters.targets || []);
    }
  }, [currentFilters]);

  // Builds the final filters object from local states
  const buildFilterObject = () => ({
    conclusions: selectedConclusions,
    statuses: selectedStatuses,
    severities: selectedSeverities,
    createdAt: [startDate, endDate],
    alertTimestamp: [alertStartDate, alertEndDate],
    attackers: selectedAttackers,
    targets: selectedTargets,
    attackSurfaces: selectedAttackSurfaces,
    mitreStages: selectedMitreStages,
    feedback: feedbackFilters,
  });

  // Let the parent retrieve the final data by calling ref.current.getFinalFilters()
  useImperativeHandle(ref, () => ({
    getFinalFilters() {
      return buildFilterObject();
    },
  }));

  // Normal “popper” usage: apply immediately
  const handleApplyFilters = () => {
    applyFilters(buildFilterObject());
    if (!asDialog) onClose();
  };

  // Normal “popper” usage: save a new custom filter
  const handleSaveFilterClick = () => {
    onSaveCustomFilter(buildFilterObject());
    if (!asDialog) onClose();
  };

  // If not editing, show "Apply" & "Save" & "Clear".
  // If editing, we'll rely on the parent’s “Update” button & remove the "Update" + "Clear" from inside here.
  const handleClearAll = () => {
    setSelectedConclusions([]);
    setSelectedStatuses([]);
    setSelectedSeverities([]);
    setSelectedAttackSurfaces([]);
    setSelectedMitreStages([]);
    setFeedbackFilters([]);
    setStartDate(null);
    setEndDate(null);
    setAlertStartDate(null);
    setAlertEndDate(null);
    setSelectedAttackers([]);
    setSelectedTargets([]);
  };

  const handleCheckboxChange =
    (setter: React.Dispatch<React.SetStateAction<string[]>>, value: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter((prev) =>
        e.target.checked ? [...prev, value] : prev.filter((v) => v !== value)
      );
    };

  const feedbackOptions = [
    { value: "agree", label: "Agree" },
    { value: "disagree", label: "Disagree" },
    { value: "has_feedback", label: "Has Feedback" },
    { value: "no_feedback", label: "No Feedback" },
  ];

  const totalFiltersApplied =
    selectedConclusions.length +
    selectedStatuses.length +
    selectedSeverities.length +
    (startDate || endDate ? 1 : 0) +
    (alertStartDate || alertEndDate ? 1 : 0) +
    selectedAttackers.length +
    selectedTargets.length +
    selectedAttackSurfaces.length +
    selectedMitreStages.length +
    feedbackFilters.length;

  const content = (
    <>
      {/* For normal popper usage, show a header */}
      {!asDialog && (
        <Box
          sx={{
            backgroundColor: headerRowStyles.backgroundColor,
            color: headerRowStyles.color,
            padding: headerRowStyles.padding,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottom: headerRowStyles.borderBottom,
            flexShrink: 0,
          }}
        >
          <Typography variant="h6">Filter Cases</Typography>
        </Box>
      )}

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          paddingX: 2,
          paddingTop: asDialog ? 2 : 0,
          width: asDialog ? 400 : 320,
          maxHeight: asDialog ? "60vh" : "80vh",
        }}
      >
        {/* Conclusion */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedConclusions.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Conclusion
              </Typography>
            </Badge>
          </Box>
          <FormGroup>
            {conclusions.map((c) => (
              <FormControlLabel
                key={c}
                control={
                  <Checkbox
                    checked={selectedConclusions.includes(c)}
                    onChange={handleCheckboxChange(setSelectedConclusions, c)}
                    sx={{
                      color: theme.palette.info.main,
                      "&.Mui-checked": {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                }
                label={c === "Malicious_NoAction" ? "Malicious-NoAction" : c}
              />
            ))}
          </FormGroup>
        </Box>
        {/* Feedback */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={feedbackFilters.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Feedback
              </Typography>
            </Badge>
          </Box>
          <FormGroup>
            {feedbackOptions.map((opt) => (
              <FormControlLabel
                key={opt.value}
                control={
                  <Checkbox
                    checked={feedbackFilters.includes(opt.value)}
                    onChange={handleCheckboxChange(
                      setFeedbackFilters,
                      opt.value
                    )}
                    sx={{
                      color: theme.palette.info.main,
                      "&.Mui-checked": {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                }
                label={opt.label}
              />
            ))}
          </FormGroup>
        </Box>
        {/* Severity */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedSeverities.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Severity
              </Typography>
            </Badge>
          </Box>
          <FormGroup>
            {severities.map((severity) => (
              <FormControlLabel
                key={severity}
                control={
                  <Checkbox
                    checked={selectedSeverities.includes(severity)}
                    onChange={handleCheckboxChange(
                      setSelectedSeverities,
                      severity
                    )}
                    sx={{
                      color: theme.palette.info.main,
                      "&.Mui-checked": {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                }
                label={severity}
              />
            ))}
          </FormGroup>
        </Box>
        {/* Attack Surface */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedAttackSurfaces.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Attack Surface
              </Typography>
            </Badge>
          </Box>
          <FormGroup>
            {attackSurfaces.map((as) => (
              <FormControlLabel
                key={as}
                control={
                  <Checkbox
                    checked={selectedAttackSurfaces.includes(as)}
                    onChange={handleCheckboxChange(
                      setSelectedAttackSurfaces,
                      as
                    )}
                    sx={{
                      color: theme.palette.info.main,
                      "&.Mui-checked": {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                }
                label={as}
              />
            ))}
          </FormGroup>
        </Box>
        {/* MITRE */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedMitreStages.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                MITRE
              </Typography>
            </Badge>
          </Box>
          <FormGroup>
            {mitreStages.map((m) => (
              <FormControlLabel
                key={m}
                control={
                  <Checkbox
                    checked={selectedMitreStages.includes(m)}
                    onChange={handleCheckboxChange(setSelectedMitreStages, m)}
                    sx={{
                      color: theme.palette.info.main,
                      "&.Mui-checked": {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                }
                label={m}
              />
            ))}
          </FormGroup>
        </Box>
        {/* Embed Rcvd */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={startDate || endDate ? 1 : 0}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Embed Rcvd
              </Typography>
            </Badge>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              slots={{
                textField: (props) => (
                  <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
                ),
              }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              slots={{
                textField: (props) => (
                  <TextField {...props} size="small" sx={{ flex: 1 }} />
                ),
              }}
            />
          </Box>
        </Box>
        {/* Alert Time */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={alertStartDate || alertEndDate ? 1 : 0}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Alert Time
              </Typography>
            </Badge>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <DatePicker
              label="Start Date"
              value={alertStartDate}
              onChange={(date) => setAlertStartDate(date)}
              slots={{
                textField: (props) => (
                  <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
                ),
              }}
            />
            <DatePicker
              label="End Date"
              value={alertEndDate}
              onChange={(date) => setAlertEndDate(date)}
              slots={{
                textField: (props) => (
                  <TextField {...props} size="small" sx={{ flex: 1 }} />
                ),
              }}
            />
          </Box>
        </Box>
        {/* Identity */}
        {/* <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={identities.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Identity
              </Typography>
            </Badge>
          </Box>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
            {identities.map((identity) => (
              <Chip
                key={identity}
                label={identity}
                onDelete={() =>
                  setIdentities((prev) => prev.filter((i) => i !== identity))
                }
              />
            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
              value={identityInput}
              onChange={(e) => setIdentityInput(e.target.value)}
              placeholder="Search Identity"
              size="small"
              sx={{ flex: 1, mr: 1 }}
            />
            <Button
              variant="outlined"
              onClick={() => {
                const trimmed = identityInput.trim();
                if (trimmed && !identities.includes(trimmed)) {
                  setIdentities([...identities, trimmed]);
                }
                setIdentityInput("");
              }}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                },
              }}
            >
              Add
            </Button>
          </Box>
        </Box> */}
        {/* Attacker */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedAttackers.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Attacker
              </Typography>
            </Badge>
          </Box>

          {/* Show selected attackers as chips */}
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
            {selectedAttackers.map((attacker) => (
              <Chip
                key={attacker}
                label={attacker}
                onDelete={() =>
                  setSelectedAttackers((prev) =>
                    prev.filter((a) => a !== attacker)
                  )
                }
              />
            ))}
          </Box>

          {/* Input to add attacker */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
              value={attackerInput}
              onChange={(e) => setAttackerInput(e.target.value)}
              placeholder="Enter Attacker"
              size="small"
              sx={{ flex: 1, mr: 1 }}
            />
            <Button
              variant="outlined"
              onClick={() => {
                const trimmed = attackerInput.trim();
                if (trimmed && !selectedAttackers.includes(trimmed)) {
                  setSelectedAttackers([...selectedAttackers, trimmed]);
                }
                setAttackerInput("");
              }}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                },
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        {/* Target */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Badge
              badgeContent={selectedTargets.length}
              color="primary"
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Target
              </Typography>
            </Badge>
          </Box>

          {/* Show selected targets as chips */}
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
            {selectedTargets.map((target) => (
              <Chip
                key={target}
                label={target}
                onDelete={() =>
                  setSelectedTargets((prev) => prev.filter((t) => t !== target))
                }
              />
            ))}
          </Box>

          {/* Input to add target */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
              value={targetInput}
              onChange={(e) => setTargetInput(e.target.value)}
              placeholder="Enter Target"
              size="small"
              sx={{ flex: 1, mr: 1 }}
            />
            <Button
              variant="outlined"
              onClick={() => {
                const trimmed = targetInput.trim();
                if (trimmed && !selectedTargets.includes(trimmed)) {
                  setSelectedTargets([...selectedTargets, trimmed]);
                }
                setTargetInput("");
              }}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                },
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Footer */}
      {!isEditing && ( // HIDE these if we’re in edit mode
        <Box
          sx={{
            flexShrink: 0,
            borderTop: asDialog ? 0 : `1px solid ${theme.palette.divider}`,
            p: 2,
            width: asDialog ? 400 : "auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                },
              }}
            >
              {`Apply Filters (${totalFiltersApplied})`}
            </Button>

            <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
              <Button
                variant="text"
                onClick={handleSaveFilterClick}
                sx={{
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[200],
                  },
                }}
              >
                Save
              </Button>

              <Button
                onClick={handleClearAll}
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[300]
                      : theme.palette.grey[700],
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[200],
                  },
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );

  if (asDialog && !open) {
    return null;
  }

  if (asDialog) {
    return <Box sx={{ overflowY: "auto" }}>{content}</Box>;
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 320,
          maxHeight: "80vh",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          boxShadow: 3,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1000,
        }}
      >
        {content}
      </Box>
    );
  }
};

// Wrap with `forwardRef` so we can use the FilterPopupRef in the parent
const FilterPopup = forwardRef(FilterPopupRender);
export default FilterPopup;

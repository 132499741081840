export interface RawNode {
  data: any;
  id: string;
  displayName?: string;
  decision?: string; // "Malicious" / "Benign" etc.
  kind?: string;
  service?: string;
}

export interface RawEdge {
  u: string; // parent
  v: string; // child
}

export interface AdjacencyMap {
  [id: string]: {
    node: RawNode;
    children: RawNode[];
    parents: Set<string>; // optional if you want to track who points to me
  };
}

export function buildAdjList(nodes: RawNode[], edges: RawEdge[]): AdjacencyMap {
  const map: AdjacencyMap = {};
  // init
  for (const n of nodes) {
    map[n.id] = {
      node: n,
      children: [],
      parents: new Set<string>(),
    };
  }
  // fill children
  for (const e of edges) {
    const parentEntry = map[e.u];
    const childEntry = map[e.v];
    if (parentEntry && childEntry) {
      parentEntry.children.push(childEntry.node);
      childEntry.parents.add(e.u);
    }
  }
  return map;
}

import React from "react";
import { Box, Typography } from "@mui/material";
import LoadingAnimation from "../../../common/components/LoadingAnimation";
import robot404Icon from "../../../assets/robot-404-icon.png";

interface EmailImageSectionProps {
  emailImageUrl: string;
  imageData: string | null;
  error: string | null;
}

const EmailImageSection: React.FC<EmailImageSectionProps> = ({
  emailImageUrl,
  imageData,
  error,
}) => {
  // Show a loading animation while we fetch the image (no data and no error).
  if (!imageData && !error) {
    return (
      <div style={{ width: "100%", height: "50vh" }}>
        <LoadingAnimation />
      </div>
    );
  }

  // If there's an error, show the 404 "robot" graphic in a fancy container.
  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          p: 2,
          position: "relative",
          // background: "linear-gradient(135deg, #ECE9E6 0%, #FFFFFF 100%)",
          // Define the keyframes in sx
          "@keyframes shakeOnce": {
            "0%, 100%": { transform: "translateX(0)" },
            "25%": { transform: "translateX(-10px)" },
            "50%": { transform: "translateX(10px)" },
            "75%": { transform: "translateX(-5px)" },
          },
        }}
      >
        <Box
          component="img"
          src={robot404Icon}
          alt="404 Not Found"
          sx={{
            width: "400px",
            maxWidth: "50%",
            mb: 2,
            // Apply the shake animation once
            animation: "shakeOnce 1.5s ease-in-out 0s 1 forwards",
          }}
        />
        <Typography variant="h6" sx={{ mb: 1 }}>
          Oops! We couldn’t load the email snapshot.
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: "center" }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  // Otherwise, show the fetched image.
  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        overflowY: "auto",
        padding: "1rem",
      }}
    >
      {imageData ? (
        <img
          src={imageData}
          alt="Email Snapshot"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "8px",
            objectFit: "contain",
          }}
        />
      ) : (
        <Box>Error loading image</Box>
      )}
    </Box>
  );
};

export default EmailImageSection;

import React from "react";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import YearlyTimeSaved from "../../../assets/time-saved-icon.svg";
import DollarsSaved from "../../../assets/yr-dollars-saved-icon.svg";
import SystemHealth from "../../../assets/system-health-icon.svg";

// Utility to format numbers with commas
const formatNumber = (value: string | number): string => {
  if (typeof value === "number") {
    return value.toLocaleString(); // Adds commas to numbers
  }
  return value.toString();
};

interface MetricCardProps {
  title: string;
  value: string | number;
  subtitle?: string;
  icon?: React.ReactNode;
  showHealthLine?: boolean;
  /**
   * If tooltip is passed, we'll render a tooltip with multiline content (via React elements).
   */
  tooltip?: React.ReactNode;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  subtitle,
  icon,
  showHealthLine = false,
  tooltip,
}) => {
  const theme = useTheme();

  // Utility to format numbers
  const formatNumber = (val: string | number): string => {
    if (typeof val === "number") {
      return val.toLocaleString();
    }
    return val.toString();
  };

  // The main "card" content without tooltip
  const cardContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: icon ? "row" : "column",
        alignItems: icon ? "center" : "flex-start",
        gap: theme.spacing(2),
        p: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        // Slightly reduce padding on narrower screens
        [theme.breakpoints.down("xl")]: {
          p: theme.spacing(2),
          gap: theme.spacing(1.5),
        },
      }}
    >
      {icon && (
        <Box
          sx={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("xl")]: {
              width: 45,
              height: 45,
            },
          }}
        >
          {icon}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: icon ? "flex-start" : "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey[500],
            fontSize: "0.85rem",
            fontWeight: 400,
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.7rem",
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.primary,
            fontSize: "1.60rem",
            fontWeight: 700,
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
            [theme.breakpoints.down("xl")]: {
              fontSize: "1.2rem",
            },
          }}
        >
          {formatNumber(value)}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            sx={{ fontSize: "0.75rem", color: theme.palette.grey[500] }}
          >
            {subtitle}
          </Typography>
        )}
        {showHealthLine && (
          <Box
            sx={{
              height: 4,
              width: 100,
              backgroundColor: theme.palette.success.main,
              borderRadius: 2,
            }}
          />
        )}
      </Box>
    </Box>
  );

  if (!tooltip) {
    return cardContent;
  }

  return (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      title={tooltip}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.grey[900],
            color: "#fff",
            p: 2,
            borderRadius: 1,
            boxShadow: theme.shadows[4],
            fontSize: "0.9rem",
            maxWidth: 250,
          },
        },
        arrow: {
          sx: {
            color: theme.palette.grey[900],
          },
        },
      }}
    >
      <Box>{cardContent}</Box>
    </Tooltip>
  );
};

interface DashboardHeaderProps {
  metrics: {
    timeSaved: string;
    yearlyDollarsSaved: string;
    systemHealth: string;
    evidenceCollected: number;
    denoisedAlerts: number;
    unreviewedMaliciousAlerts: number;
  };
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ metrics }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(1),
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: theme.spacing(2),
          [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
            gap: theme.spacing(1),
          },
        }}
      >
        <MetricCard
          title="Time Saved"
          value={metrics.timeSaved}
          subtitle="(monthly)"
          icon={<img src={YearlyTimeSaved} alt="time saved icon" />}
          tooltip={
            <Box>
              {/* <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                Time Saved Calculation
              </Typography> */}
              <Typography variant="body2">
                <strong>Formula:</strong> (# of cases in last 30 days) × 18min
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[400], display: "block", mt: 1 }}
              >
                Assumes each case saves ~18 minutes.
              </Typography>
            </Box>
          }
        />
        <MetricCard
          title="Yr. Dollars Saved"
          value={metrics.yearlyDollarsSaved}
          subtitle="(projected)"
          icon={<img src={DollarsSaved} alt="dollars saved icon" />}
          tooltip={
            <Box>
              {/* <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                Yearly Dollars Saved (Projected)
              </Typography> */}
              <Typography variant="body2">
                <strong>Formula:</strong> (hours saved in last 30 days × 12 ×
                125,000) ÷ 2,080
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: theme.palette.grey[400], display: "block", mt: 1 }}
              >
                Assumes annual salary of $125k at 2,080 hours/year.
              </Typography>
            </Box>
          }
        />
        <MetricCard
          title="System Health"
          value={metrics.systemHealth}
          icon={<img src={SystemHealth} alt="System health icon" />}
          showHealthLine
        />
        <MetricCard title="Total Alerts" value={metrics.evidenceCollected} />
        <MetricCard title="Denoised %" value={`${metrics.denoisedAlerts}%`} />
        <MetricCard
          title="Unreviewed Malicious Alerts"
          value={metrics.unreviewedMaliciousAlerts}
        />
      </Box>
    </Paper>
  );
};

export default DashboardHeader;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Tabs,
  Tab,
  Badge,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import AnalysisTabContent from "./AnalysisTabContent";
import ArtifactsTab from "./ArtifactsTab";

interface URLData {
  benign: string[];
  malicious: string[];
  inconclusive: string[];
}

interface Attachment {
  filename: string;
  mimetype: string;
  hash: string;
}

interface AttachmentData {
  benign: Attachment[];
  malicious: Attachment[];
  inconclusive: Attachment[];
}

interface HeadersData {
  suspiciousHeaders?: string[];
  // etc...
}

interface CTAData {
  urgent?: boolean;
  // etc...
}

interface GenericAnalysisData {
  // fallback shape for unknown data
  [key: string]: any;
}

interface Question {
  q: string;
  a: string;
}

interface Analysis {
  name: string;
  kind: string;
  conclusion: string;
  summary: string;
  data?: any;
  questions?: { q: string; a: string }[];
}

interface InvestigativeStepsTabsProps {
  analyses: Analysis[];
  artifacts?: Record<string, any>;
}

/**
 * Helper that sums up known arrays for "URLs" or "Attachments" data
 */
function countUrlData(data: URLData) {
  return (
    (data.benign?.length ?? 0) +
    (data.malicious?.length ?? 0) +
    (data.inconclusive?.length ?? 0)
  );
}

function countAttachmentData(data: AttachmentData) {
  return (
    (data.benign?.length ?? 0) +
    (data.malicious?.length ?? 0) +
    (data.inconclusive?.length ?? 0)
  );
}

/**
 * Fallback to check if data has arrays we want to count
 */
function countGenericData(data: GenericAnalysisData) {
  let total = 0;
  // Optionally, can pick which fields to sum, or sum all arrays found:
  for (const [key, val] of Object.entries(data)) {
    if (Array.isArray(val)) {
      total += val.length;
    }
  }
  return total;
}

const InvestigativeStepsTabs: React.FC<InvestigativeStepsTabsProps> = ({
  analyses = [],
  artifacts = {},
}) => {
  const theme = useTheme();
  const tabsRef = useRef<HTMLDivElement | null>(null);

  // 1) Find index of first malicious tab if any
  const maliciousTabIndex = analyses.findIndex(
    (analysis) => analysis.conclusion === "Malicious"
  );
  const [activeTab, setActiveTab] = useState(
    maliciousTabIndex !== -1 ? maliciousTabIndex : 0
  );

  // 2) Build list of tab names + final "Artifacts"
  const analysisTabNames = analyses.map((a) => a.name);
  const tabsToRender = [...analysisTabNames, "Artifacts"];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // 3) Scroll the selected tab into view on mount/tab change
  useEffect(() => {
    if (!tabsRef.current) return;
    const selectedTab = tabsRef.current.querySelector(
      '[role="tab"][aria-selected="true"]'
    ) as HTMLElement | null;
    if (selectedTab) {
      const timer = setTimeout(() => {
        selectedTab.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [activeTab]);

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: theme.shadows[1],
      }}
    >
      <Box ref={tabsRef}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            "& .MuiTab-root": {
              fontSize: "0.875rem",
              minHeight: "2.5rem",
              minWidth: "130px",
              flex: 1,
              paddingTop: "1.3rem",
              paddingBottom: "1rem",
              textTransform: "none",
              color: theme.palette.grey[600],
              [theme.breakpoints.down("xl")]: {
                fontSize: "0.8rem",
              },
              "&.Mui-selected": {
                color: theme.palette.text.primary,
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.info.main,
            },
          }}
        >
          {tabsToRender.map((tabName, index) => {
            const isArtifactsTab = index === tabsToRender.length - 1;

            if (analyses.length === 0 && !isArtifactsTab) {
              // If no analyses at all, just show plain tab
              return <Tab key={index} label={tabName} />;
            }

            // If this is the final Artifacts tab:
            if (isArtifactsTab) {
              return <Tab key={index} label="Artifacts" />;
            }

            // Otherwise, we do have an analysis for this index
            const analysis = analyses[index];
            const isMalicious = analysis.conclusion === "Malicious";
            let badgeVariant: "dot" | "standard" | undefined = undefined;
            let badgeColor: "error" | "primary" | undefined;
            let badgeContent: React.ReactNode = null;

            // 4) If malicious, show dot
            if (isMalicious) {
              badgeVariant = "dot";
              badgeColor = "error";
            } else if (analysis.data) {
              // 5) Specialized logic for known names
              if (analysis.name === "URLs") {
                const count = countUrlData(analysis.data as URLData);
                if (count > 0) {
                  badgeVariant = "standard";
                  badgeColor = "primary";
                  badgeContent = count > 9 ? "9+" : count;
                }
              } else if (analysis.name === "Attachments") {
                const count = countAttachmentData(
                  analysis.data as AttachmentData
                );
                if (count > 0) {
                  badgeVariant = "standard";
                  badgeColor = "primary";
                  badgeContent = count > 9 ? "9+" : count;
                }
              } else if (analysis.name === "Headers") {
                // For “Headers”, maybe we check if data.suspiciousHeaders?
                // Or fallback to a generic approach
                const possibleHeaders = (analysis.data as HeadersData)
                  ?.suspiciousHeaders;
                if (
                  Array.isArray(possibleHeaders) &&
                  possibleHeaders.length > 0
                ) {
                  badgeVariant = "standard";
                  badgeColor = "primary";
                  badgeContent =
                    possibleHeaders.length > 9 ? "9+" : possibleHeaders.length;
                }
              } else if (analysis.name === "Call To Action") {
                // For “CTA”, maybe do something else, or fallback
                // Example: if CTA is urgent, we show a dot (like a warning)
                const ctaData = analysis.data as CTAData;
                if (ctaData?.urgent) {
                  // show a dot as a signal of urgency
                  badgeVariant = "dot";
                  badgeColor = "primary";
                }
              } else {
                // 6) Fallback generic approach
                const count = countGenericData(
                  analysis.data as GenericAnalysisData
                );
                if (count > 0) {
                  badgeVariant = "standard";
                  badgeColor = "primary";
                  badgeContent = count > 9 ? "9+" : count;
                }
              }
            }

            // 7) Build the label
            const tabLabel = <Box>{tabName}</Box>;

            // 8) If we have a badge, wrap it in <Badge>
            if (badgeVariant) {
              return (
                <Tab
                  key={index}
                  label={
                    <Badge
                      color={badgeColor}
                      variant={badgeVariant}
                      badgeContent={badgeContent}
                      showZero
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        "& .MuiBadge-badge": {
                          top: isMalicious ? "-2%" : "20%",
                          right: isMalicious ? "-10px" : "-8px",
                        },
                      }}
                    >
                      {tabLabel}
                    </Badge>
                  }
                />
              );
            }

            // If no badge is needed, just plain tab
            return <Tab key={index} label={tabName} />;
          })}
        </Tabs>
      </Box>

      {/* Rendered Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {tabsToRender.map((_, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? "block" : "none",
              width: "100%",
              height: "100%",
            }}
          >
            {/* If this is the Artifacts tab (last one) */}
            {index === tabsToRender.length - 1 ? (
              <ArtifactsTab artifacts={artifacts} />
            ) : analyses.length === 0 ? (
              // No analyses at all
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" fontWeight="bold">
                  No Data
                </Typography>
              </Box>
            ) : (
              // Render the analysis content
              <AnalysisTabContent analysis={analyses[index]} />
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default InvestigativeStepsTabs;

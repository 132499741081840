import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { useTheme } from "@mui/material/styles";

/** A helper for truncating text >10 chars and showing a tooltip with the full. */
const TruncatedWithTooltip: React.FC<{
  text?: string;
  maxLength?: number;
}> = ({ text = "", maxLength = 10 }) => {
  if (text.length <= maxLength) {
    return <Typography variant="body2">{text}</Typography>;
  }

  // Create truncated version + show the full in a tooltip
  const truncated = text.slice(0, maxLength) + "...";
  return (
    <Tooltip title={text}>
      <Typography variant="body2" sx={{ cursor: "normal" }}>
        {truncated}
      </Typography>
    </Tooltip>
  );
};

interface CaseInfoSectionProps {
  device: string;
  from?: string;
  attacker?: string;
  target?: string;
  attackSurface: string;
}

const CaseInfoSection: React.FC<CaseInfoSectionProps> = ({
  device,
  from,
  attacker,
  target,
  attackSurface,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* Device */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          marginRight: theme.spacing(4),
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <EmailIcon sx={{ color: theme.palette.grey[500], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Device
          </Typography>
          <Typography variant="body2">{device}</Typography>
        </Box>
      </Box>

      {/* Attacker or From (depending on attackSurface) */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          marginRight: theme.spacing(4),
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <PermIdentityIcon
            sx={{ color: theme.palette.grey[500], fontSize: 20 }}
          />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {attackSurface === "Email" ? "From" : "Attacker"}
          </Typography>
          <Typography variant="body2">
            {attackSurface === "Email" ? from : attacker}
          </Typography>
        </Box>
      </Box>

      {/* Target */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          marginRight: theme.spacing(4),
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <GpsFixedIcon sx={{ color: theme.palette.grey[500], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Target
          </Typography>
          <Typography variant="body2">{target}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseInfoSection;

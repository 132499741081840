import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Case } from "../../cases/types/types";

interface CaseNavigationState {
  source: "dashboard" | "cases" | null; // To track from where we navigated
  filteredCases: Case[]; // The filtered list of cases
  currentIndex: number; // The currently selected case index
}

const initialState: CaseNavigationState = {
  source: null,
  filteredCases: [],
  currentIndex: 0,
};

const caseNavigationSlice = createSlice({
  name: "caseNavigation",
  initialState,
  reducers: {
    setCaseListAndIndex(
      state,
      action: PayloadAction<{
        source: "dashboard" | "cases";
        cases: Case[];
        index: number;
      }>
    ) {
      state.source = action.payload.source;
      state.filteredCases = action.payload.cases;
      state.currentIndex = action.payload.index;
    },
    setCurrentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    clearCaseNavigation(state) {
      state.source = null;
      state.filteredCases = [];
      state.currentIndex = 0;
    },
  },
});

export const { setCaseListAndIndex, setCurrentIndex, clearCaseNavigation } =
  caseNavigationSlice.actions;
export default caseNavigationSlice.reducer;

import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  useTheme,
  Paper,
  Tooltip,
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DownloadIcon from "@mui/icons-material/Download";

import InvestigativeStepsTabs from "./InvestigativeStepsTabs";
import ReactFlowSection from "./ReactFlowSection";

interface CloudFlowAndInvestigativeStepsProps {
  caseDetail: any;
  caseId: string;
  analyses: any;
  hasNavigation: boolean;
  canGoPrevious: boolean;
  canGoNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

const CloudFlowAndInvestigativeSteps: React.FC<
  CloudFlowAndInvestigativeStepsProps
> = ({
  caseDetail,
  caseId,
  analyses = [],
  hasNavigation,
  canGoPrevious,
  canGoNext,
  onPrevious,
  onNext,
}) => {
  const [selectedView, setSelectedView] = useState<"graph" | "rawlogs">(
    "graph"
  );
  const theme = useTheme();

  const handleViewRawLogs = () => {
    // For future usage: fetch logs or show a separate view
    // Right now, disabled
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(72vh - 64px)",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      {/* Button Row */}
      <Box sx={{ display: "flex", gap: "1rem", marginBottom: "0.01rem" }}>
        {/* View Event Graph */}
        <Button
          onClick={() => setSelectedView("graph")}
          variant={selectedView === "graph" ? "contained" : "outlined"}
          startIcon={<AccountTreeIcon />}
          sx={{
            color:
              selectedView === "graph"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "graph"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View Event Graph
        </Button>

        {/* View Raw Logs (disabled) */}
        <Tooltip title="Coming Soon" placement="top">
          <span>
            <Button
              onClick={handleViewRawLogs}
              variant={selectedView === "rawlogs" ? "contained" : "outlined"}
              startIcon={<DownloadIcon />}
              disabled // Currently disabled
              sx={{
                color: theme.palette.grey[500],
                borderColor: theme.palette.grey[500],
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: theme.palette.grey[200],
                  borderColor: theme.palette.grey[500],
                },
              }}
            >
              View Raw Logs
            </Button>
          </span>
        </Tooltip>

        {/* Navigation Buttons Group (pushed right) */}
        {hasNavigation && (
          <Box sx={{ marginLeft: "auto", display: "flex", gap: "0.5rem" }}>
            <Button
              variant="outlined"
              onClick={onPrevious}
              disabled={!canGoPrevious}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": {
                  borderColor: theme.palette.info.dark,
                },
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={onNext}
              disabled={!canGoNext}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": {
                  borderColor: theme.palette.info.dark,
                },
              }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>

      {/* Main Content Row */}
      <Box sx={{ display: "flex", gap: "1rem", width: "100%", height: "100%" }}>
        {/* Main View */}
        <Box
          sx={{
            flexBasis: "70%",
            flexGrow: 1,
            height: "95%",
            position: "relative",
          }}
        >
          {selectedView === "graph" && (
            <ReactFlowSection
              data={caseDetail.graph}
              artifacts={caseDetail.artifacts}
              caseId={caseId}
            />
          )}
          {selectedView === "rawlogs" && (
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              <Box>Raw Logs view is not implemented yet.</Box>
            </Paper>
          )}
        </Box>

        {/* Investigative Steps */}
        <Box
          sx={{ flexBasis: "30%", flexGrow: 1, maxWidth: "30%", height: "95%" }}
        >
          <InvestigativeStepsTabs
            analyses={analyses}
            artifacts={caseDetail.artifacts}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CloudFlowAndInvestigativeSteps;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { Case } from "../features/cases/types/types";
import { transformCasesApiResponse } from "../features/cases/helpers/transformCasesApiResponse";

export const fetchCases = createAsyncThunk(
  "cases/fetchCases",
  async (accessToken: string, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const apiUrl = process.env.REACT_APP_CASES_API_URL as string;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // Parse error response
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Failed to fetch cases");
      }

      const data = await response.json();
      return transformCasesApiResponse(data);
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch cases");
    }
  }
);

// sends raw bytes from the selected file
export const uploadEmail = createAsyncThunk(
  "cases/uploadEmail",
  async (
    { file, accessToken }: { file: File; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      // Read the file as ArrayBuffer (raw bytes)
      const arrayBuffer = await file.arrayBuffer();
      const apiUrl = process.env.REACT_APP_EMBED_EMAIL_UPLOAD_URL as string;

      // POST raw bytes with fetch
      const response = await fetch(apiUrl, {
        method: "POST",
        body: arrayBuffer, // raw bytes
        headers: {
          // 'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        return rejectWithValue(
          errorData.message || `Upload failed with status ${response.status}`
        );
      }

      // data might contain { email_id: '...' }
      const data = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to upload email");
    }
  }
);

import React, { useState } from "react";
import { Box, Typography, Divider, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/material/styles";

const VALUE_TRUNCATE_LENGTH = 20;

/** Converts keys like "cli_command" -> "CLI Command" */
function prettifyKey(key: string): string {
  return key
    .split("_")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ")
    .replace(/\bCli\b/, "CLI")
    .replace(/\bApi\b/, "API")
    .replace(/\bIp\b/, "IP");
}

/** Convert booleans/null/undefined to readable values */
function formatValue(value: any): string {
  if (typeof value === "boolean") return value ? "Yes" : "No";
  if (value === null || value === undefined) return "N/A";
  return String(value);
}

interface CloudNodeDetailsProps {
  nodeId: string;
  data: Record<string, any>;
}

/** Styled Cloud Node Details similar to `ArtifactDetails` */
const CloudNodeDetails: React.FC<CloudNodeDetailsProps> = ({
  nodeId,
  data,
}) => {
  const sortedKeys = Object.keys(data).sort();

  if (sortedKeys.length === 0) {
    return (
      <Box
        sx={{
          p: 2,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="body2" fontWeight="bold">
          {nodeId}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          No additional metadata available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
        {nodeId}
      </Typography>

      {/* Key-Value Data List */}
      {sortedKeys.map((key, idx) => (
        <React.Fragment key={key}>
          <DataRow rowKey={key} value={data[key]} />
          {idx < sortedKeys.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default CloudNodeDetails;

/* -------------------------------------------
   DataRow: Handles both primitives and nested data
------------------------------------------- */
interface DataRowProps {
  rowKey: string;
  value: any;
}

const DataRow: React.FC<DataRowProps> = ({ rowKey, value }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const isObject = value && typeof value === "object" && !Array.isArray(value);
  const isArray = Array.isArray(value);
  const displayValue = formatValue(value);

  if (isObject || isArray) {
    // Show "Object" or "Array(...)"
    return (
      <Box
        sx={{
          py: 1,
          display: "grid",
          gridTemplateColumns: "60% 40%",
          alignItems: "center",
        }}
      >
        {/* KEY */}
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            pr: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}
            noWrap
          >
            {prettifyKey(rowKey)}
          </Typography>
        </Box>

        {/* VALUE + expand icon */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="body2">
            {isArray ? `Array(${value.length})` : "Object"}
          </Typography>

          {expanded ? (
            <ExpandLessIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          )}
        </Box>

        {/* If expanded, show nested data */}
        {expanded && (
          <Box sx={{ gridColumn: "1 / span 2", mt: 1, ml: 2 }}>
            <NestedData data={value} />
          </Box>
        )}
      </Box>
    );
  }

  // Otherwise, it's a primitive => show key + truncated value
  return (
    <Box
      sx={{
        py: 1,
        display: "grid",
        gridTemplateColumns: "35% 65%",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          pr: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontWeight: 500 }}
          noWrap
        >
          {prettifyKey(rowKey)}
        </Typography>
      </Box>

      <Box sx={{ textAlign: "right", pr: 1 }}>
        <InlineExpandText
          text={displayValue}
          maxChars={VALUE_TRUNCATE_LENGTH}
        />
      </Box>
    </Box>
  );
};

/* -------------------------------------------
   NestedData: Handles rendering nested objects & arrays
------------------------------------------- */
const NestedData: React.FC<{ data: any }> = ({ data }) => {
  if (Array.isArray(data)) {
    return (
      <Box>
        {data.map((item, idx) => (
          <React.Fragment key={idx}>
            <DataRow rowKey={`[${idx}]`} value={item} />
            {idx < data.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    );
  }

  if (data && typeof data === "object") {
    const keys = Object.keys(data).sort();
    return (
      <Box>
        {keys.map((k, i) => (
          <React.Fragment key={k}>
            <DataRow rowKey={k} value={data[k]} />
            {i < keys.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    );
  }

  // Primitive fallback
  const text = formatValue(data);
  return <InlineExpandText text={text} maxChars={VALUE_TRUNCATE_LENGTH} />;
};

/* -------------------------------------------
   InlineExpandText: handles long text expansion
------------------------------------------- */
const InlineExpandText: React.FC<{ text: string; maxChars: number }> = ({
  text,
  maxChars,
}) => {
  const [expanded, setExpanded] = useState(false);

  if (text.length <= maxChars) {
    return (
      <Typography variant="body2" noWrap>
        {text}
      </Typography>
    );
  }

  if (expanded) {
    return (
      <Typography
        variant="body2"
        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
      >
        {text}{" "}
        <Link
          component="button"
          variant="body2"
          onClick={() => setExpanded(false)}
          sx={{ ml: 0.5 }}
        >
          [Less]
        </Link>
      </Typography>
    );
  }

  return (
    <Typography variant="body2" noWrap>
      {text.slice(0, maxChars)}…{" "}
      <Link
        component="button"
        variant="body2"
        onClick={() => setExpanded(true)}
        sx={{ ml: 0.5 }}
      >
        [More]
      </Link>
    </Typography>
  );
};

import React, { useState } from "react";
import {
  Container,
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
  Card,
  CardActionArea,
  Checkbox,
  IconButton,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import embedLogo from "../assets/embed-logo-text.svg";
import onboardImage from "../assets/onboard-dashboard-img.svg";
import { Add, CoPresent, Slideshow } from "@mui/icons-material";

const alertSources = [
  {
    name: "Splunk",
    description:
      "The Splunk product has produced alerts in 60% of true positive cases.",
  },
  {
    name: "Azure Sentinel",
    description:
      "Offers high accuracy in detecting real-time threats, improving security response.",
  },
  {
    name: "Panther",
    description:
      "Efficient in spotting compliance and security issues, with robust alerting capabilities.",
  },
  {
    name: "Crowdstrike",
    description:
      "Provides effective alerts for detecting advanced persistent threats.",
  },
  {
    name: "Sentinel One",
    description:
      "Crucial for identifying and responding to endpoint security threats.",
  },
  {
    name: "AWS",
    description:
      "Delivers key alerts for monitoring and managing cloud security risks.",
  },
];

const Onboarding = () => {
  const theme = useTheme();
  const [step, setStep] = useState<number>(1);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedSources, setSelectedSources] = useState<string[]>([]);

  const [formData, setFormData] = useState<{
    role: string;
    teamName: string;
    users: string[];
  }>({
    role: "",
    teamName: "",
    users: [],
  });

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toggleSelection = (source: string) => {
    setSelectedSources((prev) =>
      prev.includes(source)
        ? prev.filter((s) => s !== source)
        : [...prev, source]
    );
  };

  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: "40px",
        marginTop: "80px",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding={4}
        position="absolute"
        top={0}
      >
        <img
          src={embedLogo}
          alt="Embed Security Logo"
          style={{ height: "40px" }}
        />
        <Button
          variant="outlined"
          style={{
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[500],
          }}
        >
          Log Out
        </Button>
      </Box>

      {/* Welcome/Landing Page */}
      {step === 1 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          mt={4}
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: theme.typography.h3.fontFamily,
              fontWeight: theme.typography.h3.fontWeight,
              fontSize: theme.typography.h3.fontSize,
              color: theme.palette.text.primary,
            }}
          >
            Welcome to{" "}
            <span style={{ color: theme.palette.primary.main }}>
              Embed Security!
            </span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 500,
              fontSize: "14px",
              marginTop: "10px",
              color: theme.palette.grey[500],
            }}
          >
            Welcome to Embed Security, your comprehensive platform for real-time
            alert detection, threat intelligence, and incident response. Our
            platform is designed to help you stay ahead of potential threats and
            ensure the security of your assets. During onboarding process, you
            will go through a quick setup to get started.
          </Typography>
          <img
            src={onboardImage}
            alt="Onboarding Dashboard"
            style={{ width: "130%", marginTop: "20px" }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNext}
            style={{
              marginTop: "-120px",
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            Get Started
          </Button>
        </Box>
      )}

      {/*  Experience: Demo or Get Started */}
      {step === 2 && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            marginTop={6}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.typography.h3.fontFamily,
                fontWeight: theme.typography.h3.fontWeight,
                fontSize: theme.typography.h3.fontSize,
                color: theme.palette.text.primary,
              }}
            >
              Choose Your{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Experience
              </span>
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
                fontSize: "14px",
                marginTop: "10px",
                color: theme.palette.text.primary,
              }}
            >
              To personalize your team's onboarding experience, please select an
              option below. Selecting "Demo" will place you into Embed's demo
              instance where you can immediately work with our platform. "Get
              Started" will place you into your team's workspace.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" gap={6} marginTop={4}>
            <Card
              style={{
                padding: "40px",
                textAlign: "center",
                minWidth: "280px",
                position: "relative",
                cursor: "pointer",
                boxShadow: theme.shadows[3],
                border:
                  selectedOption === "Demo"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid transparent",
                backgroundColor:
                  selectedOption === "Demo"
                    ? theme.palette.primary.light
                    : "white",
              }}
              onClick={() => setSelectedOption("Demo")}
            >
              <CardActionArea>
                <Checkbox
                  checked={selectedOption === "Demo"}
                  style={{
                    position: "absolute",
                    top: -30,
                    right: -30,
                    color:
                      selectedOption === "Demo"
                        ? theme.palette.primary.dark
                        : theme.palette.grey[500],
                  }}
                />
                <CoPresent
                  style={{
                    fontSize: 60,
                    color:
                      selectedOption === "Demo"
                        ? theme.palette.primary.dark
                        : theme.palette.grey[500],
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", fontSize: "12px" }}
                >
                  Demo
                </Typography>
              </CardActionArea>
            </Card>
            <Card
              style={{
                padding: "40px",
                textAlign: "center",
                minWidth: "280px",
                position: "relative",
                cursor: "pointer",
                boxShadow: theme.shadows[3],
                border:
                  selectedOption === "Get Started"
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid transparent",
                backgroundColor:
                  selectedOption === "Get Started"
                    ? theme.palette.primary.light
                    : "white",
              }}
              onClick={() => setSelectedOption("Get Started")}
            >
              <CardActionArea>
                <Checkbox
                  checked={selectedOption === "Get Started"}
                  style={{
                    position: "absolute",
                    top: -30,
                    right: -30,
                    color:
                      selectedOption === "Get Started"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.main,
                  }}
                />
                <Slideshow
                  style={{
                    fontSize: 60,
                    color:
                      selectedOption === "Get Started"
                        ? theme.palette.primary.dark
                        : theme.palette.grey[500],
                  }}
                />
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", fontSize: "12px" }}
                >
                  Get Started
                </Typography>
              </CardActionArea>
            </Card>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            marginTop={3}
          >
            <Button
              variant="outlined"
              style={{
                color: theme.palette.grey[700],
                borderColor: theme.palette.grey[500],
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!selectedOption}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      {/* Create Your Team */}
      {step === 3 && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            marginTop={6}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.typography.h3.fontFamily,
                fontWeight: theme.typography.h3.fontWeight,
                fontSize: theme.typography.h3.fontSize,
                color: theme.palette.text.primary,
              }}
            >
              Create Your{" "}
              <span style={{ color: theme.palette.primary.main }}>Team</span>
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
                fontSize: "14px",
                marginTop: "10px",
                color: theme.palette.grey[500],
              }}
            >
              As a Team User, you have the ability to create and manage a team.
              Enter your team's name and size. You can also invite team members
              now or choose to do it later.
            </Typography>
          </Box>
          <Card
            style={{
              padding: "20px",
              marginTop: "20px",
              width: "100%",
              boxShadow: theme.shadows[3],
            }}
          >
            <Typography variant="body2" fontWeight="bold">
              Team Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "20px" }}
            />
            <Typography variant="body2" fontWeight="bold">
              Invite Users (Optional)
            </Typography>
            <Box display="flex" alignItems="center" marginTop="10px">
              <TextField
                fullWidth
                placeholder="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <IconButton color="primary" style={{ marginLeft: "10px" }}>
                <Add />
              </IconButton>
            </Box>
          </Card>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            marginTop={3}
          >
            <Button
              variant="outlined"
              style={{
                color: theme.palette.grey[700],
                borderColor: theme.palette.grey[500],
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!teamName}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      {step === 3 && (
        <>
          <Typography variant="h5">Provisioning Your Instance</Typography>
          <CircularProgress style={{ marginTop: "20px" }} />
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            This may take a few moments...
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            style={{ marginTop: "20px" }}
          >
            Continue
          </Button>
        </>
      )}

      {step === 4 && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            marginTop={6}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: theme.typography.h3.fontFamily,
                fontWeight: theme.typography.h3.fontWeight,
                fontSize: theme.typography.h3.fontSize,
                color: theme.palette.text.primary,
              }}
            >
              Set up{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Alert Sources
              </span>
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
                fontSize: "14px",
                marginTop: "10px",
                color: theme.palette.grey[500],
              }}
            >
              We need you to add at least one Alert ingestion source. This is
              how we kickstart your investigations by bringing in real-time
              notifications about potential security threats from various
              systems.
            </Typography>
          </Box>
          <Grid container spacing={3} marginTop={3}>
            {alertSources.map((source) => (
              <Grid item xs={12} sm={6} key={source.name}>
                <Card
                  style={{
                    padding: "20px",
                    boxShadow: theme.shadows[3],
                    border: selectedSources.includes(source.name)
                      ? `2px solid ${theme.palette.primary.main}`
                      : "2px solid transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleSelection(source.name)}
                >
                  <Typography variant="h6" fontWeight="bold">
                    {source.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "10px",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {source.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "15px" }}
                  >
                    Connect
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            marginTop={3}
          >
            <Button
              variant="outlined"
              style={{
                color: theme.palette.grey[700],
                borderColor: theme.palette.grey[500],
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={selectedSources.length === 0}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Onboarding;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { transformCaseDetailApiResponse } from "../features/case-details/helpers/transformCaseDetailApiResponse";

export const fetchCaseDetail = createAsyncThunk(
  "caseDetail/fetchCaseDetail",
  async (
    { accessToken, caseId }: { accessToken: string; caseId: string },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }
    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseId}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // so we can keep polling / not treat it as a final error
      if (response.status === 404) {
        return {
          httpStatus: 202, // same as "still processing"
          message: "Case not found yet (treating 404 as still analyzing)",
        };
      }

      // If 202 => still processing ---
      if (response.status === 202) {
        return {
          httpStatus: 202,
          message: "Case is still being analyzed",
        };
      }

      // For other non-OK, handle error ---
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));

        // Default/fallback message
        let finalMessage = "Failed to fetch case details";

        // Check if we have a "detail" array from Pydantic
        if (
          errorData.detail &&
          Array.isArray(errorData.detail) &&
          errorData.detail.length > 0
        ) {
          // For example, errorData.detail[0].msg may have "Input should be a valid UUID..."
          const msgs = errorData.detail
            .map((d: any) => d.msg || "")
            .filter(Boolean);
          if (msgs.length > 0) {
            finalMessage = msgs.join(" | ");
          }
        }
        // else if there's a top-level message
        else if (errorData.message) {
          finalMessage = errorData.message;
        }

        return rejectWithValue({
          message: finalMessage,
          code: response.status,
          details: errorData,
        });
      }

      // If 200 => transform the data as usual ---
      const data = await response.json();
      return {
        httpStatus: 200,
        ...transformCaseDetailApiResponse(data),
      };
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch case detail");
    }
  }
);

// submitFeedback action
export const submitFeedback = createAsyncThunk<
  { position: "agree" | "disagree"; reason: string }, // Return type
  {
    accessToken: string;
    caseId: string;
    position: "agree" | "disagree";
    reason?: string;
  }, // Argument type
  { rejectValue: string }
>(
  "caseDetail/submitFeedback",
  async (
    { accessToken, caseId, position, reason = "User provided feedback" },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_FEEDBACK}/${caseId}/feedback`;

    const body = {
      position,
      reason,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        let errorMessage = "Failed to submit feedback";
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } catch (err) {
          // If response is empty or not JSON, keep the default error message
        }
        return rejectWithValue(errorMessage);
      }

      // Since the response body is empty, return the feedback we submitted
      return { position, reason };
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to submit feedback");
    }
  }
);

export const fetchEmlData = createAsyncThunk<
  string, // Return type for the fulfilled action
  { accessToken: string; caseId: string }, // Argument type
  { rejectValue: string } // Rejected action type
>(
  "caseDetail/fetchEmlData",
  async ({ accessToken, caseId }, { rejectWithValue }) => {
    // For example: GET /api/v1alpha/cases/:caseId/files/eml
    const apiUrl = `${process.env.REACT_APP_CASES_API_URL}/${caseId}/files/eml`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.text(); // Assuming server returns error as text
        return rejectWithValue(errorData || "Failed to fetch .eml data");
      }

      const data = await response.text(); // Get the email data as text
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "An unexpected error occurred while fetching .eml data"
      );
    }
  }
);

export const reprocessCase = createAsyncThunk<
  string, // Return type
  { accessToken: string; caseId: string }, // Arguments
  { rejectValue: string } // Rejected value
>("caseDetail/reprocessCase", async ({ accessToken, caseId }, thunkApi) => {
  const { rejectWithValue, dispatch } = thunkApi;

  if (!accessToken) {
    return rejectWithValue("User is not authenticated");
  }

  const startUrl = `${process.env.REACT_APP_REPROCESS_CASE}/${caseId}/reprocess`;
  try {
    const startResp = await fetch(startUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (startResp.status !== 204) {
      const text = await startResp.text().catch(() => "Failed to reprocess");
      return rejectWithValue(text || `Expected 204, got ${startResp.status}`);
    }
  } catch (error: any) {
    return rejectWithValue(error.message || "Failed to start reprocess");
  }

  // poll with GET /cases/{id}/reprocess => { status: 'Done' | 'Processing' }
  const pollUrl = `${process.env.REACT_APP_REPROCESS_CASE}/${caseId}/reprocess`;
  const maxAttempts = 30;
  const delay = 30000;

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    // Wait a bit before the next check, except on the first attempt
    if (attempt > 0) {
      await new Promise((res) => setTimeout(res, delay));
    }

    try {
      const pollResp = await fetch(pollUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!pollResp.ok) {
        const errText = await pollResp.text().catch(() => "");
        return rejectWithValue(
          errText || `GET reprocess returned ${pollResp.status}`
        );
      }

      // parse 'Done' | 'Processing'
      const data = await pollResp.json().catch(() => ({}));

      if (data === "Done") {
        // done => refresh case detail
        await dispatch(fetchCaseDetail({ accessToken, caseId })).unwrap();

        return "Reprocess complete";
      }
      // else if 'Processing', loop again
    } catch (pollErr: any) {
      return rejectWithValue(pollErr.message || "Error polling reprocess");
    }
  }

  // 4) If we exit loop, we timed out
  return rejectWithValue("Timed out waiting for reprocess to finish");
});

export const startReprocessCase = createAsyncThunk<
  void, // no special return
  { accessToken: string; caseId: string },
  { rejectValue: string }
>(
  "caseDetail/startReprocessCase",
  async ({ accessToken, caseId }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const apiUrl = `${process.env.REACT_APP_REPROCESS_CASE}/${caseId}/reprocess`;
    try {
      const resp = await fetch(apiUrl, {
        method: "PUT", // Start the reprocess
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (resp.status !== 204) {
        const text = await resp.text().catch(() => "Failed to start reprocess");
        return rejectWithValue(text);
      }
      // If 204, success => no return value
      return;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to start reprocess");
    }
  }
);

export const pollReprocessCase = createAsyncThunk<
  string, // e.g. "Reprocess complete"
  { accessToken: string; caseId: string },
  { rejectValue: string }
>(
  "caseDetail/pollReprocessCase",
  async ({ accessToken, caseId }, { rejectWithValue, dispatch }) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const pollUrl = `${process.env.REACT_APP_REPROCESS_CASE}/${caseId}/reprocess`;
    const maxAttempts = 10;
    const delay = 5000;

    try {
      for (let attempt = 0; attempt < maxAttempts; attempt++) {
        // Wait after the first iteration
        if (attempt > 0) {
          await new Promise((res) => setTimeout(res, delay));
        }

        const pollResp = await fetch(pollUrl, {
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (!pollResp.ok) {
          const errText = await pollResp.text().catch(() => "");
          return rejectWithValue(
            errText || `GET reprocess returned ${pollResp.status}`
          );
        }

        const data = await pollResp.json().catch(() => ({}));

        if (data === "Done") {
          // Refresh the case
          await dispatch(fetchCaseDetail({ accessToken, caseId })).unwrap();
          return "Reprocess complete";
        }
        // else keep looping if status === "Processing"
      }
      return rejectWithValue("Timed out waiting for reprocess to finish");
    } catch (err: any) {
      return rejectWithValue(err.message || "Error polling reprocess");
    }
  }
);

/**
 * Download an attachment by filehash from the server, which returns:
 *   { base64: "...base64-encoded payload..." }
 * We decode it, create a Blob, and trigger a browser download.
 */
export const downloadAttachment = createAsyncThunk<
  void, // We do not store the downloaded bytes in Redux, so return type is void
  {
    accessToken: string;
    caseId: string;
    filehash: string;
    filename: string;
    mimetype: string;
  },
  { rejectValue: string }
>(
  "caseDetail/downloadAttachment",
  async (
    { accessToken, caseId, filehash, filename, mimetype },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    try {
      // For example: GET /api/v1alpha/cases/:caseId/files/attachments/:filehash
      const url = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseId}/files/attachments/${filehash}`;

      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        // No need for 'responseType' in fetch; we can do resp.blob() below
      });

      if (resp.status === 404) {
        return rejectWithValue("Attachment not found (404).");
      }
      if (!resp.ok) {
        return rejectWithValue(
          `Failed to download file. Status: ${resp.status}`
        );
      }

      // Read the body as a binary Blob
      const blob = await resp.blob();

      // Then create a temporary URL for that blob:
      const blobUrl = URL.createObjectURL(blob);

      // Programmatically "click" an <a> element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename || "attachment";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL after a short delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
    } catch (err: any) {
      return rejectWithValue(
        err?.message || "Error occurred while downloading attachment"
      );
    }
  }
);

/**
 * Fetch a .png preview for a given previewId.
 * We do:
 *   GET ${REACT_APP_CASE_DETAILS_URL}${previewId}
 * This endpoint should return raw PNG bytes, so we read as blob() and createObjectURL.
 */
export const fetchArtifactPreview = createAsyncThunk<
  string, // We return a string: the Object URL that can place in an <img src="..." />
  { accessToken: string; previewId: string }, // The arguments
  { rejectValue: string }
>(
  "caseDetail/fetchArtifactPreview",
  async ({ accessToken, previewId }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    try {
      // For example, if previewId = "/cases/123/artifacts/abc/preview"
      // then final fetch URL = REACT_APP_CASE_DETAILS_URL + "/cases/123/artifacts/abc/preview"
      const baseUrl = process.env.REACT_APP_CASE_DETAILS_URL;
      if (!baseUrl) {
        return rejectWithValue("No REACT_APP_CASE_DETAILS_URL is defined");
      }

      // Strip out any leading "/cases" from previewId
      const normalizedPreviewId = previewId.replace(/^\/cases/, "");
      const fetchUrl = baseUrl + normalizedPreviewId;

      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.text().catch(() => "");
        return rejectWithValue(
          errorData || `Failed to fetch artifact preview (${response.status})`
        );
      }

      // We get raw bytes as a blob
      const blob = await response.blob();
      // Convert blob to an object URL so we can <img src="..." />
      const objectUrl = URL.createObjectURL(blob);
      return objectUrl;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Failed to fetch artifact preview"
      );
    }
  }
);

// Attachment preview fetch:
export const fetchAttachmentPreview = createAsyncThunk<
  string, // Return type (the object URL string)
  { accessToken: string; caseId: string; filehash: string },
  { rejectValue: string }
>(
  "caseDetail/fetchAttachmentPreview",
  async ({ accessToken, caseId, filehash }, { rejectWithValue }) => {
    // Endpoint: /cases/{caseId}/files/attachments/{filehash}/preview
    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseId}/files/attachments/${filehash}/preview`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text().catch(() => "");
        return rejectWithValue(
          errorText || `Failed to fetch attachment preview (${response.status})`
        );
      }

      // The server returns a JSON array of base64-encoded strings
      const base64Chunks: string[] = await response.json();
      const combinedBase64 = base64Chunks.join("");

      // Convert base64 -> raw bytes
      const byteCharacters = atob(combinedBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob
      const blob = new Blob([byteArray], { type: "image/png" });
      const objectUrl = URL.createObjectURL(blob);

      return objectUrl;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "Failed to fetch attachment preview"
      );
    }
  }
);

import { Case } from "../types/types";

/**
 * Transforms the new "cases" API response data into our existing `Case` shape.
 */
export function transformCasesApiResponse(apiCases: any[]): Case[] {
  return apiCases.map((apiCase) => {
    return {
      // old "caseId" => new "id"
      caseId: apiCase.id ?? null,

      // old "title" => new "title"
      title: apiCase.title ?? null,

      // old "createdAt" => new "createdAt"
      createdAt: apiCase.createdAt ?? null,

      // old "alertTimestamp" => new "alertTimestamp"
      alertTimestamp: apiCase.alertTimestamp ?? null,

      // old "device" => new "device"
      device: apiCase.device ?? null,

      // old "severity" => new "severity"
      severity: apiCase.severity ?? null,

      // old "conclusion" => new "decision"
      conclusion: apiCase.decision ?? null,

      // old "analyses" => new "analyses"
      analyses: (apiCase.analyses ?? []).map((analysis: any) => ({
        id: analysis.id ?? null,
        name: analysis.name ?? null,
        conclusion: analysis.decision ?? null,
      })),

      // old "attackSurface" => new "attackSurface"
      attackSurface: apiCase.attackSurface ?? null,

      // old "mitreStage" => new "mitreTactics"
      mitreStage: apiCase.mitreTactics?.join(", ") ?? null,

      // old "identity" => new "identity"
      identity: apiCase.identity ?? null,

      // old "status" => new "state"
      status: apiCase.state ?? null,

      // old "analyst" => new "analyst"
      analyst: apiCase.analyst ?? null,

      // feedback & feedbackReason
      feedback: apiCase.lastFeedback?.position
        ? apiCase.lastFeedback.position.toLowerCase()
        : null,
      feedbackReason: apiCase.lastFeedback?.reason ?? null,

      // first element from attacker & target arrays, if they exist
      attacker:
        Array.isArray(apiCase.attacker) && apiCase.attacker.length > 0
          ? apiCase.attacker[0]
          : null,
      target:
        Array.isArray(apiCase.target) && apiCase.target.length > 0
          ? apiCase.target[0]
          : null,
    };
  });
}
